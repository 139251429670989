<template>
  <div class="introduction">
    <div class="main">
      <div class="banner">
        <img :src="pagePicture" />
      </div>
      <div class="list" v-html="pageContent"></div>
    </div>
  </div>
</template>
 
<script>
import { queryPageManageList } from "@/api/api.js";
export default {
  name: "introduction",
  data() {
    return {
      pageContent: "", //文本内容
      pagePicture: "", //图片
    };
  },
  created() {
    queryPageManageList({ pageName: "公司介绍" }).then((res) => {
      if (res.data.rows[0]) {
        this.pagePicture = res.data.rows[0].pagePicture;
        this.pageContent = res.data.rows[0].pageContent;
      }
    });
  },
  methods: {},
};
</script>
 
<style scoped lang="less">
* {
  list-style: none;
  margin: 0;
  padding: 0;
}
.introduction {
  width: 100%;
  .main {
    width: 80%;
    margin: 50px auto;
    .banner {
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .list {
    }
  }
  @media screen and (max-width: 768px) {
    .main {
      width: 90%;
    }
  }
}
</style>
